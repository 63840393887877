/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/**
 * Expanded sizing for beyond 1280px screen widths
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/**
 * CONTENTS
 *
 * #Introduction........Naming conventions used throughout the code.
 *
 * #SETTINGS
 * Variables............Globally-available variables and config.
 *
 * #TOOLS
 * Mixins...............Useful mixins.
 *
 * #GENERIC
 * Demo styles..........Styles for demo only (consider removing these).
 *
 * #BASE
 * Raw styles...........The very basic component wrapper.
 * Modifiers............The basic styles dependant on component placement.
 * Debuggers............The basic styles dependant on component placement.
 *
 * #BUTTONS
 * Base..................Wrapping and constraining every button.
 * Modifiers.............Styles that depends on state and settings.
 * Animations............Main animations of the component.
 * Debuggers.............Styles for development.
 *
 * #LABELS
 * Base..................Wrapping and constraining every label.
 * Modifiers.............Styles that depends on state and settings.
 * Debuggers.............Styles for development.
 *
 * #DEVELOPMENT
 * In development........These styles are in development and not yet finalised
 * Debuggers.............Helper styles and flags for development.
 */
/*------------------------------------*\
    #Introduction
\*------------------------------------*/
/**
 * The code AND the comments use naming conventions to refer to each part of
 * the UI put in place by this component. If you see that somewhere they are
 * not followed please consider a Pull Request. The naming conventions are:
 *
 * "Component" : the widget itself as a whole. This is the last time it will be
 *               called anything different than "component". So, stay away from
 *               "widget", "button" or anything else when referring to the
 *               Component in general.
 *
 * "Main Button" : the button that is always in view. Hovering or clicking on it
 *                 will reveal the child buttons.
 *
 * "Child buttons" : if you've read the previous point you know what they are.
 *                   Did you read the previous point? :)
 *
 * "Label(s)" : the tooltip that fades in when hovering over a button.

/*------------------------------------*\
    #SETTINGS | Variables
\*------------------------------------*/
/**
 * These variables are the default styles that serve as fallback and can be
 * easily customised at compile time.
 * Consider overriding them in your own style sheets rather than editing them
 * here. Refer to the docs for more info.
 */
/* COLORS ----------------------------*/
/* EFFECTS ---------------------------*/
/* SPEEDS ----------------------------*/
/* SIZES -----------------------------*/
/* SPACING ---------------------------*/
/* OTHER VARIABLES -------------------*/
/*------------------------------------*\
    #BASE | Raw styles
\*------------------------------------*/
/**
 * The very core styling of the button.
 * These styles are shared by every instance of the button.
 * Styles placed here should NOT care about placement in the screen,
 * options chosen by the user or state of the button.
 */
.mfb-component, .mfb-component--br, .mfb-component--bl, .mfb-component--tr, .mfb-component--tl {
  box-sizing: border-box;
  margin: 52px 32px;
  position: fixed;
  white-space: nowrap;
  z-index: 30;
  padding-left: 0;
  list-style: none;
}

.mfb-component *, .mfb-component--br *, .mfb-component--bl *, .mfb-component--tr *, .mfb-component--tl *, .mfb-component *:before, .mfb-component--br *:before, .mfb-component--bl *:before, .mfb-component--tr *:before, .mfb-component--tl *:before, .mfb-component *:after, .mfb-component--br *:after, .mfb-component--bl *:after, .mfb-component--tr *:after, .mfb-component--tl *:after {
  box-sizing: inherit;
  list-style-type: none;
}

/*------------------------------------*\
    #BASE | Modifiers
\*------------------------------------*/
/**
 * These styles depends on the placement of the button.
 * Styles can be:
 * 1. Top-left:  modified by the " --tl " suffix.
 * 2. Top-right: modified by the " --tr " suffix.
 * 3. Bottom-left:  modified by the " --bl " suffix.
 * 4. Bottom-right: modified by the " --br " suffix.
 */
.mfb-component--tl {
  left: 0;
  top: 0;
}

.mfb-component--tr {
  right: 0;
  top: 0;
}

.mfb-component--bl {
  left: 0;
  bottom: 0;
}

.mfb-component--br {
  right: 0;
  bottom: 0;
  z-index: 30;
}

/*------------------------------------*\
    #BUTTONS | Base
\*------------------------------------*/
.mfb-component__button, .mfb-component__button--child, .mfb-component__button--main {
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  position: relative;
  -webkit-user-drag: none;
  font-weight: bold;
  color: #f1f1f1;
}

/**
 * This is the unordered list for the list items that contain
 * the child buttons.
 *
 */
.mfb-component__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mfb-component__list > li {
  display: block;
  position: absolute;
  top: 0;
  right: 1px;
  padding: 10px 0;
  margin: -10px 0;
}

/**
 * These are the basic styles for all the icons inside the main button
 */
.mfb-component__icon, .mfb-component__child-icon, .mfb-component__main-icon--active,
.mfb-component__main-icon--resting {
  position: absolute;
  font-size: 18px;
  text-align: center;
  line-height: 56px;
  width: 100%;
}

.mfb-component__wrap {
  padding: 52px 52px 52px 10px;
  margin: -52px;
}

[data-mfb-state=open] .mfb-component__icon, [data-mfb-state=open] .mfb-component__child-icon, [data-mfb-state=open] .mfb-component__main-icon--active,
[data-mfb-state=open] .mfb-component__main-icon--resting,
[data-mfb-toggle=hover]:hover .mfb-component__icon,
[data-mfb-toggle=hover]:hover .mfb-component__child-icon,
[data-mfb-toggle=hover]:hover .mfb-component__main-icon--active,
[data-mfb-toggle=hover]:hover .mfb-component__main-icon--resting {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}

/*------------------------------------*\
    #BUTTONS | Modifiers
\*------------------------------------*/
.mfb-component__button--main {
  height: 56px;
  width: 56px;
  z-index: 20;
}

.mfb-component__button--child {
  height: 56px;
  width: 56px;
}

.mfb-component__main-icon--active,
.mfb-component__main-icon--resting {
  -webkit-transform: scale(1) rotate(360deg);
  transform: scale(1) rotate(360deg);
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 1, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 1, 1);
}

.mfb-component__child-icon,
.mfb-component__child-icon {
  line-height: 56px;
  font-size: 18px;
}

.mfb-component__main-icon--active {
  opacity: 0;
}

[data-mfb-state=open] .mfb-component__main-icon,
[data-mfb-toggle=hover]:hover .mfb-component__main-icon {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
[data-mfb-state=open] .mfb-component__main-icon--resting,
[data-mfb-toggle=hover]:hover .mfb-component__main-icon--resting {
  opacity: 0;
}
[data-mfb-state=open] .mfb-component__main-icon--active,
[data-mfb-toggle=hover]:hover .mfb-component__main-icon--active {
  opacity: 1;
}

/*------------------------------------*\
    #BUTTONS | Animations
\*------------------------------------*/
/**
 * SLIDE IN + FADE
 * When hovering the main button, the child buttons slide out from beneath
 * the main button while transitioning from transparent to opaque.
 *
 */
.mfb-component--tl.mfb-slidein .mfb-component__list li,
.mfb-component--tr.mfb-slidein .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
}

.mfb-component--bl.mfb-slidein .mfb-component__list li,
.mfb-component--br.mfb-slidein .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
}

.mfb-component--tl.mfb-slidein[data-mfb-state=open] .mfb-component__list li, .mfb-component--tl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li,
.mfb-component--tr.mfb-slidein[data-mfb-state=open] .mfb-component__list li,
.mfb-component--tr.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li {
  opacity: 1;
}
.mfb-component--tl.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
}
.mfb-component--tl.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px);
  transform: translateY(140px);
}
.mfb-component--tl.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px);
  transform: translateY(210px);
}
.mfb-component--tl.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px);
  transform: translateY(280px);
}
.mfb-component--tl.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(5), .mfb-component--tl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(350px);
  transform: translateY(350px);
}

.mfb-component--bl.mfb-slidein[data-mfb-state=open] .mfb-component__list li, .mfb-component--bl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li,
.mfb-component--br.mfb-slidein[data-mfb-state=open] .mfb-component__list li,
.mfb-component--br.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li {
  opacity: 1;
}
.mfb-component--bl.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px);
  transform: translateY(-70px);
}
.mfb-component--bl.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
}
.mfb-component--bl.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px);
  transform: translateY(-210px);
}
.mfb-component--bl.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px);
  transform: translateY(-280px);
}
.mfb-component--bl.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(5), .mfb-component--bl.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-slidein[data-mfb-state=open] .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-slidein[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-350px);
  transform: translateY(-350px);
}

/**
 * SLIDE IN SPRING
 * When hovering the main button, the child buttons slide out from beneath
 * the main button while transitioning from transparent to opaque.
 *
 */
.mfb-component--tl.mfb-slidein-spring .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li, .mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li {
  opacity: 1;
}
.mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
}
.mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px);
  transform: translateY(140px);
}
.mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px);
  transform: translateY(210px);
}
.mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px);
  transform: translateY(280px);
}
.mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(5), .mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(350px);
  transform: translateY(350px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li,
.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li {
  opacity: 1;
}
.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px);
  transform: translateY(-70px);
}
.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
}
.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px);
  transform: translateY(-210px);
}
.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px);
  transform: translateY(-280px);
}
.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-350px);
  transform: translateY(-350px);
}

/**
 * ZOOM-IN
 * When hovering the main button, the child buttons grow
 * from zero to normal size.
 *
 */
.mfb-component--tl.mfb-zoomin .mfb-component__list li,
.mfb-component--tr.mfb-zoomin .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(0);
  transform: translateY(70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.2s;
}
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(0);
  transform: translateY(140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(0);
  transform: translateY(210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(0);
  transform: translateY(280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}
.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(350px) scale(0);
  transform: translateY(350px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li,
.mfb-component--br.mfb-zoomin .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}
.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(0);
  transform: translateY(-70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.2s;
}
.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(0);
  transform: translateY(-140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}
.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(0);
  transform: translateY(-210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}
.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(0);
  transform: translateY(-280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}
.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-350px) scale(0);
  transform: translateY(-350px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(1);
  transform: translateY(70px) scale(1);
  transition-delay: 0.05s;
}
.mfb-component--tl.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(1);
  transform: translateY(140px) scale(1);
  transition-delay: 0.1s;
}
.mfb-component--tl.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(1);
  transform: translateY(210px) scale(1);
  transition-delay: 0.15s;
}
.mfb-component--tl.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(1);
  transform: translateY(280px) scale(1);
  transition-delay: 0.2s;
}
.mfb-component--tl.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(5), .mfb-component--tl.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(350px) scale(1);
  transform: translateY(350px) scale(1);
  transition-delay: 0.25s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(1);
  transform: translateY(-70px) scale(1);
  transition-delay: 0.05s;
}
.mfb-component--bl.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(1);
  transform: translateY(-140px) scale(1);
  transition-delay: 0.1s;
}
.mfb-component--bl.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(1);
  transform: translateY(-210px) scale(1);
  transition-delay: 0.15s;
}
.mfb-component--bl.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(1);
  transform: translateY(-280px) scale(1);
  transition-delay: 0.2s;
}
.mfb-component--bl.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(5), .mfb-component--bl.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-zoomin[data-mfb-state=open] .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-zoomin[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-350px) scale(1);
  transform: translateY(-350px) scale(1);
  transition-delay: 0.25s;
}

/**
 * FOUNTAIN
 * When hovering the main button the child buttons
 * jump into view from outside the viewport
 */
.mfb-component--tl.mfb-fountain .mfb-component__list li,
.mfb-component--tr.mfb-fountain .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(0);
  transform: translateY(-70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.2s;
}
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(0);
  transform: translateY(-140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(0);
  transform: translateY(-210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(0);
  transform: translateY(-280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}
.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-350px) scale(0);
  transform: translateY(-350px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li,
.mfb-component--br.mfb-fountain .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(0);
  transform: translateY(70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.2s;
}
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(0);
  transform: translateY(140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(0);
  transform: translateY(210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(0);
  transform: translateY(280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}
.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(350px) scale(0);
  transform: translateY(350px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--tl.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(1);
  transform: translateY(70px) scale(1);
  transition-delay: 0.05s;
}
.mfb-component--tl.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(1);
  transform: translateY(140px) scale(1);
  transition-delay: 0.1s;
}
.mfb-component--tl.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(1);
  transform: translateY(210px) scale(1);
  transition-delay: 0.15s;
}
.mfb-component--tl.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(1);
  transform: translateY(280px) scale(1);
  transition-delay: 0.2s;
}
.mfb-component--tl.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(5), .mfb-component--tl.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(350px) scale(1);
  transform: translateY(350px) scale(1);
  transition-delay: 0.25s;
}

.mfb-component--bl.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(1);
  transform: translateY(-70px) scale(1);
  transition-delay: 0.05s;
}
.mfb-component--bl.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(1);
  transform: translateY(-140px) scale(1);
  transition-delay: 0.1s;
}
.mfb-component--bl.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(1);
  transform: translateY(-210px) scale(1);
  transition-delay: 0.15s;
}
.mfb-component--bl.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(1);
  transform: translateY(-280px) scale(1);
  transition-delay: 0.2s;
}
.mfb-component--bl.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(5), .mfb-component--bl.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-fountain[data-mfb-state=open] .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-fountain[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-350px) scale(1);
  transform: translateY(-350px) scale(1);
  transition-delay: 0.25s;
}

/*------------------------------------*\
    #LABELS | base
\*------------------------------------*/
/**
 * These are the labels associated to each button,
 * exposed only when hovering the related button.
 * They are called labels but are in fact data-attributes of
 * each button (an anchor tag).
 */
[data-mfb-label]:after {
  content: attr(data-mfb-label);
  opacity: 0;
  transition: all 0.5s;
  background: rgb(0, 0, 0);
  padding: 4px 10px;
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -10.5px;
  transition: all 0.5s;
}

[data-mfb-toggle=hover] [data-mfb-label]:hover:after,
[data-mfb-state=open] [data-mfb-label]:after {
  content: attr(data-mfb-label);
  opacity: 1;
  transition: all 0.3s;
}

/*------------------------------------*\
    #LABELS | Modifiers
\*------------------------------------*/
.mfb-component--br [data-mfb-label]:after, .mfb-component--tr [data-mfb-label]:after {
  content: attr(data-mfb-label);
  right: 55px;
  white-space: nowrap;
}

.mfb-component--tl [data-mfb-label]:after, .mfb-component--bl [data-mfb-label]:after {
  content: attr(data-mfb-label);
  left: 55px;
  white-space: nowrap;
}

.mfb-component--br .mfb-component__list [data-mfb-label]:after, .mfb-component--tr .mfb-component__list [data-mfb-label]:after {
  content: attr(data-mfb-label);
  right: 55px;
  white-space: nowrap;
}

.mfb-component--tl .mfb-component__list [data-mfb-label]:after, .mfb-component--bl .mfb-component__list [data-mfb-label]:after {
  content: attr(data-mfb-label);
  left: 55px;
  white-space: nowrap;
}

/*------------------------------------*\
    #DEVELOPMENT | In development
\*------------------------------------*/
/**
 * This part is where unfinished code should stay.
 * When a feature is ready(sh) move these styles to their proper place.
 */
/*------------------------------------*\
    #DEVELOPMENT | Debuggers
\*------------------------------------*/
/**
 * These are mainly helpers for development. They do not have to end up
 * in production but it's handy to keep them when developing.
 */
/**
 * Apply this class to the html tag when developing the slide-in button
 */
/*------------------------------------*\
    #CUSTOM | Additional CSS
              Modification for
              Gametize Purposes
\*------------------------------------*/
/* Removing labels for Activity */
/* Removing labels for Leaderboard */
/* Removing labels for Rewards */
/* Removing labels for Bookmarks */
/* Removing labels for Teams */
/* Removing labels for Your Team */
/* Styling of Font Awesome icons */
.mfb-component__wrap i {
  position: absolute;
  font-size: 1.25em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mfb-component__button--main i.fa-plus {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 10px;
  left: 10px;
}

/* Rotation of '+' to 'x' */
.mfb-component--br[data-mfb-state=closed] .mfb-component__button--main i.fa-plus,
.mfb-component--tl[data-mfb-state=closed] .mfb-component__button--main i.fa-plus,
.mfb-component--tr[data-mfb-state=closed] .mfb-component__button--main i.fa-plus,
.mfb-component--bl[data-mfb-state=closed] .mfb-component__button--main i.fa-plus {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 0.5s;
}

.mfb-component--br[data-mfb-state=open] .mfb-component__button--main i.fa-plus,
.mfb-component--tl[data-mfb-state=open] .mfb-component__button--main i.fa-plus,
.mfb-component--tr[data-mfb-state=open] .mfb-component__button--main i.fa-plus,
.mfb-component--bl[data-mfb-state=open] .mfb-component__button--main i.fa-plus {
  -webkit-transform: rotate(405deg);
  -ms-transform: rotate(405deg);
  transform: rotate(405deg);
  transition: transform 0.5s;
}

/* Media Queries & Overrides: Copied from ISEL and modified for SCSS */
.mfb-component__list > li {
  padding: 0px 0;
  margin: 0px 0;
}

.mfb-component__button--main {
  height: 40px;
  width: 40px;
  z-index: 20;
}

.mfb-component__button--child {
  height: 40px;
  width: 40px;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li, .mfb-component--br.mfb-slidein-spring .mfb-component__list li, .mfb-component--tl.mfb-slidein-spring .mfb-component__list li, .mfb-component--tr.mfb-slidein-spring .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

/* Transition Delay Overrides: Copied from ISEL too */
.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(9), .mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(9), .mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(9), .mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(9) {
  transition-delay: 0.45s;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(8), .mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(8), .mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(8), .mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(8) {
  transition-delay: 0.4s;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(7), .mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(7), .mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(7), .mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(7) {
  transition-delay: 0.35s;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(6), .mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(6), .mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(6), .mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(6) {
  transition-delay: 0.3s;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(5), .mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(5), .mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(5), .mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(5) {
  transition-delay: 0.25s;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(4), .mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(4), .mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(4) {
  transition-delay: 0.2s;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(3), .mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(3), .mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(3) {
  transition-delay: 0.15s;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(2), .mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(2), .mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(2) {
  transition-delay: 0.1s;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(1), .mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(1), .mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(1) {
  transition-delay: 0.05s;
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li, .mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li, .mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li, .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li, .mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li, .mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li, .mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li, .mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li {
  opacity: 1;
}

/* Transitions and Positioning: Bottom-left and Bottom-right */
.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(9), .mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(9), .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(9), .mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(9) {
  transition-delay: 0.45s;
  -webkit-transform: translateY(-495px);
  transform: translateY(-495px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(8), .mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(8), .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(8), .mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(8) {
  transition-delay: 0.4s;
  -webkit-transform: translateY(-440px);
  transform: translateY(-440px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(7), .mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(7), .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(7), .mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(7) {
  transition-delay: 0.35s;
  -webkit-transform: translateY(-385px);
  transform: translateY(-385px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(6), .mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(6), .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(6), .mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(6) {
  transition-delay: 0.3s;
  -webkit-transform: translateY(-330px);
  transform: translateY(-330px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5), .mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(5), .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5), .mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(5) {
  transition-delay: 0.25s;
  -webkit-transform: translateY(-275px);
  transform: translateY(-275px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(4), .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4), .mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(4) {
  transition-delay: 0.2s;
  -webkit-transform: translateY(-220px);
  transform: translateY(-220px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(3), .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3), .mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(3) {
  transition-delay: 0.15s;
  -webkit-transform: translateY(-165px);
  transform: translateY(-165px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(2), .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2), .mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(2) {
  transition-delay: 0.1s;
  -webkit-transform: translateY(-110px);
  transform: translateY(-110px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(1), .mfb-component--br.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1), .mfb-component--br.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(1) {
  transition-delay: 0.05s;
  -webkit-transform: translateY(-55px);
  transform: translateY(-55px);
}

/* Transitions and Positioning: Top-left and Top-right */
.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(9), .mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(9), .mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(9), .mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(9) {
  transition-delay: 0.45s;
  -webkit-transform: translateY(495px);
  transform: translateY(495px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(8), .mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(8), .mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(8), .mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(8) {
  transition-delay: 0.4s;
  -webkit-transform: translateY(440px);
  transform: translateY(440px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(7), .mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(7), .mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(7), .mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(7) {
  transition-delay: 0.35s;
  -webkit-transform: translateY(385px);
  transform: translateY(385px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(6), .mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(6), .mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(6), .mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(6) {
  transition-delay: 0.3s;
  -webkit-transform: translateY(330px);
  transform: translateY(330px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5), .mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(5), .mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(5), .mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(5) {
  transition-delay: 0.25s;
  -webkit-transform: translateY(275px);
  transform: translateY(275px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(4), .mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(4), .mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(4) {
  transition-delay: 0.2s;
  -webkit-transform: translateY(220px);
  transform: translateY(220px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(3), .mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(3), .mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(3) {
  transition-delay: 0.15s;
  -webkit-transform: translateY(165px);
  transform: translateY(165px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(2), .mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(2), .mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(2) {
  transition-delay: 0.1s;
  -webkit-transform: translateY(110px);
  transform: translateY(110px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(1), .mfb-component--tr.mfb-slidein-spring[data-mfb-toggle=hover]:hover .mfb-component__list li:nth-child(1), .mfb-component--tr.mfb-slidein-spring[data-mfb-state=open] .mfb-component__list li:nth-child(1) {
  transition-delay: 0.05s;
  -webkit-transform: translateY(55px);
  transform: translateY(55px);
}

/* Main Menu Icon -> collapses to cross */
.floating-menu-icon {
  width: 20px;
  height: 15px;
  margin: 12px auto;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.75s ease-in-out;
  -moz-transition: 0.75s ease-in-out;
  -o-transition: 0.75s ease-in-out;
  transition: 0.75s ease-in-out;
  cursor: pointer;
}

.floating-menu-icon span {
  height: 3px;
  display: block;
  position: absolute;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.floating-menu-icon span:nth-child(1) {
  top: 0px;
}
.floating-menu-icon span:nth-child(2), .floating-menu-icon span:nth-child(3) {
  top: 6px;
}
.floating-menu-icon span:nth-child(4) {
  top: 12px;
}

.mfb-component--br[data-mfb-state=open] .floating-menu-icon span:nth-child(1),
.mfb-component--tl[data-mfb-state=open] .floating-menu-icon span:nth-child(1),
.mfb-component--tr[data-mfb-state=open] .floating-menu-icon span:nth-child(1),
.mfb-component--bl[data-mfb-state=open] .floating-menu-icon span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}
.mfb-component--br[data-mfb-state=open] .floating-menu-icon span:nth-child(2),
.mfb-component--tl[data-mfb-state=open] .floating-menu-icon span:nth-child(2),
.mfb-component--tr[data-mfb-state=open] .floating-menu-icon span:nth-child(2),
.mfb-component--bl[data-mfb-state=open] .floating-menu-icon span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mfb-component--br[data-mfb-state=open] .floating-menu-icon span:nth-child(3),
.mfb-component--tl[data-mfb-state=open] .floating-menu-icon span:nth-child(3),
.mfb-component--tr[data-mfb-state=open] .floating-menu-icon span:nth-child(3),
.mfb-component--bl[data-mfb-state=open] .floating-menu-icon span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.mfb-component--br[data-mfb-state=open] .floating-menu-icon span:nth-child(4),
.mfb-component--tl[data-mfb-state=open] .floating-menu-icon span:nth-child(4),
.mfb-component--tr[data-mfb-state=open] .floating-menu-icon span:nth-child(4),
.mfb-component--bl[data-mfb-state=open] .floating-menu-icon span:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}
