.leaderboard-player .leaderboard-rank {
  text-align: center;
  margin-bottom: 0;
}
.leaderboard-player > div {
  display: table;
  width: 100%;
}
.leaderboard-player > div > div {
  display: table-cell;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
}
.leaderboard-player .self {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}
.leaderboard-player .photo-icon-wrap {
  position: relative;
  width: 100%;
}
.leaderboard-player .photo-icon-wrap .leaderboard-champion {
  width: 40px;
  height: 40px;
  background-image: url("../../../images/core/styling/leaderboard_champion.png");
  transform: scaleX(-1);
  background-size: contain;
  position: absolute;
  top: -20px;
  left: -15px;
  z-index: 2;
}
