/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/**
 * Expanded sizing for beyond 1280px screen widths
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/* ----------------------------------------------------
  BACKGROUND CUSTOMIZATION
---------------------------------------------------- */
/* BACKGROUND
Utilizes fixed-positioned DIV to support fixed background placement especially for browsers that
don't support fixed backgrounds for non-body DOMs.

To migrate to page-targetted backgrounds using <body> once upstreamed. */
.background-cyberwellness {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: url("../../../images/custom/login/login_bg.png") top center no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
@media screen and (max-width: 35.5em) {
  .background-cyberwellness {
    background: url("../../../images/custom/login/login_bg_portrait.png") top center no-repeat;
  }
}

/* Background targeted to specific pages */
/*body {
  &.challengePage,
  &.projectPage,
  &.loginPage,
  &.topicPage {
    background: url('../../../images/custom/login/login_bg.png') top center no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

  @media screen and (max-width: $md - 0.01em) {
    background: url('../../../images/custom/login/login_bg_portrait.png') top center no-repeat;
  }
}*/
.component-container {
  height: auto !important;
}

/* ----------------------------------------------------
 SUPPORT FOR 1920PX WIDTH
---------------------------------------------------- */
/* ---------- BASE FONT SIZE (PIXELS) ---------- */
body {
  font-size: 18px; /* Browser default */
  /* Smaller Screens */
  /* Extra Large Screens */
}
@media screen and (47.99em) {
  body {
    font-size: 16px;
  }
}
@media screen and (min-width: 120em) {
  body {
    font-size: 24px;
  }
}

/* ---------- UI STRUCTURE TO ACCOMODATE FONT SIZE CHANGES ---------- */
.topbar-wrap .topbar {
  height: 60px;
  padding-top: 10px !important;
  /* Super large screens */
}
.topbar-wrap .topbar .title-wrap {
  font-size: 1.25em;
  line-height: 1.25;
}
@media screen and (min-width: 30em) {
  .topbar-wrap .topbar .title-wrap {
    margin-top: 3px !important;
    font-size: 1.5em;
  }
}
@media screen and (min-width: 120em) {
  .topbar-wrap .topbar .back-button-text {
    margin-top: -16px !important;
  }
  .topbar-wrap .topbar .back-button-text .fas {
    top: 9px !important;
  }
}
.topbar-wrap .topbar .dbs-logo-container {
  width: 50px;
  margin-top: 5px;
}
@media screen and (min-width: 30em) {
  .topbar-wrap .topbar .dbs-logo-container {
    margin-top: 7px;
    width: 75px;
  }
}
@media screen and (min-width: 35.5em) {
  .topbar-wrap .topbar .dbs-logo-container {
    margin-right: 15px;
  }
}
@media screen and (max-width: 30em) {
  .topbar-wrap .topbar .dbs-logo-container.without-topbar-menu {
    margin-top: 12px;
  }
}

.component-container {
  padding-top: 60px !important; /* Offset topbar */
}

.progressbar {
  height: 30px !important;
  /* Super large screens */
}
@media screen and (min-width: 120em) {
  .progressbar {
    height: 40px !important;
  }
}

/* ---------- POSITION ---------- */
/* Sticky positioning once element scrolls off the screen, offsets top bar only */
.position-sticky-offset-topbar {
  top: 75px; /* Custom value: Because DBS top bar is thicker */
  z-index: 5;
}
.position-sticky-offset-topbar .progressbar .progress-text {
  margin-top: 3px;
}

/* ---------- EXTRA LARGE SCREEN SUPPORT ---------- */
@media screen and (min-width: 120em) {
  /* Container */
  .container .innerblock {
    max-width: 80em;
  }
  /* Progress bar */
  .position-sticky-offset-topbar .progressbar .progress-text {
    margin-top: 4px;
  }
}
/* ----------------------------------------------------
 OVERRIDES FOR CORE STYLING
---------------------------------------------------- */
/* ---------- LAYOUT ---------- */
html, body {
  height: auto; /* Remove vertical scrollbar issue */
}

.display-inline-block {
  display: inline-block !important;
}

@media screen and (min-width: 48em) {
  .flex-from-md {
    display: flex !important;
  }
}
/* ---------- MARGIN & PADDING ---------- */
@media screen and (max-width: 35.49em) {
  .bottommargin-below-sm {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width: 35.5em) {
  .horizontalpadding-from-sm {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .verticalpadding-from-sm {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}
/* ---------- IMAGES ---------- */
.square-image-wrapper .square-image-wrapper-overlay {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.667);
  z-index: 2;
}
.square-image-wrapper .square-image-wrapper-overlay i.fa, .square-image-wrapper .square-image-wrapper-overlay i.fas {
  position: absolute;
  font-size: 2.25em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* ---------- MENU FLOATING BUTTON ---------- */
.mfb-component--br {
  /* Super large screens */
}
@media screen and (min-width: 120em) {
  .mfb-component--br {
    font-size: 0.875em; /* Note: MFB is not conducive for larger text */
  }
}

/* ---------- PROFILE DRAWER ---------- */
.drawer-profile-wrapper {
  line-height: 1.25;
  /* Super large screens */
}
@media screen and (min-width: 120em) {
  .drawer-profile-wrapper {
    font-size: 0.875em;
    line-height: 1.125;
  }
}

@media screen and (max-width: 35.49em) {
  .drawer-profile .drawer-profile-name,
  .drawer-profile .drawer-profile-open-points {
    font-size: 1.25em;
  }
  .drawer-profile .drawer-profile-img {
    height: 30px;
    width: 30px;
  }
}
@media screen and (min-width: 35.5em) {
  .drawer-profile {
    font-size: 1.25em !important;
  }
}

.drawer-profile-wrapper-bg {
  background: rgb(0, 0, 0);
}

@media screen and (max-width: 35.49em) {
  .drawer-profile-open-points, .drawer-profile-name {
    max-width: 120px !important;
  }
  .drawer-notifications .fas {
    font-size: 1.5em !important;
    /*margin-top: 7px;*/
  }
  .drawer-notifications {
    margin-left: 10px;
  }
}
/* ----------------------------------------------------
 OTHER OVERRIDES
---------------------------------------------------- */
/* ---------- CHALLENGE CARD (Used in topic and bookmarks screen) ---------- */
.button-challenge-card {
  font-size: 1em !important;
  max-width: 200px !important;
}
@media screen and (min-width: 120em) {
  .button-challenge-card {
    max-width: 250px !important;
  }
}

/* ----------------------------------------------------
 FULLY CUSTOM STYLING
---------------------------------------------------- */
/* ---------- CUSTOM: BACKGROUND ---------- */
.bg-white {
  background: #fff;
}

/* ---------- CUSTOM: DBS LOGO ---------- */
.dbs-logo-container {
  width: 75px;
}

/* ---------- CUSTOM: HOME BUTTONS ---------- */
.mfb-component__button--main {
  height: 40px;
  width: 40px;
}

.mfb-component--br .mfb-component__list [data-mfb-label]::after, .mfb-component--tr .mfb-component__list [data-mfb-label]::after {
  font-size: 1.3em;
  top: 10px; /* vertical align */
}

/* ---------- CUSTOM: BREADCRUMBS ---------- */
.breadcrumbs {
  background: #a18282;
  font-size: 0.875em;
  color: #fff;
}
.breadcrumbs a.link {
  color: #fff;
  text-decoration: underline;
}

/* ---------- CUSTOM: USER PROFILE ---------- */
.profile .profile-container .profile-points-completions, .profile .profile-container .profile-points-completions {
  font-size: 1.25em;
}
@media screen and (max-width: 47.99em) {
  .profile .profile-container {
    font-size: 0.875em;
  }
  .profile .profile-container #completions {
    left: 15px;
  }
}
@media screen and (max-width: 47.99em) {
  .profile button#achievements, .profile button#activity {
    font-size: 0.85em;
  }
}

/* ---------- CUSTOM: MARGIN ---------- */
.bottommargin-80 {
  margin-bottom: 80px;
}
