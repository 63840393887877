.loading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.loading__dots {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 30px 0 30px 0;
}
.loading__dots__dot {
  background-color: #000;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px;
  color: #587B7F;
}
.loading__dots__dot:nth-child(1) {
  animation: bounce 0.75s 0s infinite;
}
.loading__dots__dot:nth-child(2) {
  animation: bounce 0.75s 0.2s infinite;
}
.loading__dots__dot:nth-child(3) {
  animation: bounce 0.75s 0.4s infinite;
}

@keyframes bounce {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
