/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/**
 * Expanded sizing for beyond 1280px screen widths
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/**
 * Expanded sizing for beyond 1280px screen widths
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # MARGIN & PADDING
\*------------------------------------*/
.automargin {
  margin: auto;
}

.nomargin {
  margin: 0 !important;
}

.topmargin {
  margin-top: 20px;
}

.topmargin-5 {
  margin-top: 5px;
}

.topmargin-10 {
  margin-top: 10px;
}

.topmargin-20 {
  margin-top: 20px;
}

.topmargin-30 {
  margin-top: 30px;
}

.topmargin-40 {
  margin-top: 40px;
}

.bottommargin {
  margin-bottom: 20px;
}

.bottommargin-10 {
  margin-bottom: 10px;
}

.bottommargin-20 {
  margin-bottom: 20px;
}

.bottommargin-30 {
  margin-bottom: 30px;
}

.bottommargin-40 {
  margin-bottom: 40px;
}

.leftmargin-20, .leftmargin {
  margin-left: 20px !important;
}

.leftmargin-5 {
  margin-left: 5px !important;
}

.leftmargin-10 {
  margin-left: 10px !important;
}

.leftmargin-15 {
  margin-left: 15px !important;
}

.rightmargin-20, .rightmargin {
  margin-right: 20px !important;
}

.rightmargin-5 {
  margin-right: 5px !important;
}

.rightmargin-10 {
  margin-right: 10px !important;
}

.rightmargin-15 {
  margin-right: 15px !important;
}

.verticalmargin-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.horizontalmargin-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.innerpadding {
  padding: 10px;
  box-sizing: border-box;
}

.padding {
  padding: 20px;
}

.horizontalpadding {
  padding-left: 20px;
  padding-right: 20px;
}

.horizontalpadding-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.horizontalpadding-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.verticalpadding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.verticalpadding-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.verticalpadding-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.toppadding {
  padding-top: 20px;
}

.toppadding-10 {
  padding-top: 10px;
}

.toppadding-20 {
  padding-top: 20px;
}

.toppadding-30 {
  padding-top: 30px;
}

.bottompadding {
  padding-bottom: 20px;
}

.bottompadding-5 {
  padding-bottom: 5px;
}

.bottompadding-10 {
  padding-bottom: 10px;
}

.bottompadding-20 {
  padding-bottom: 20px;
}

.bottompadding-30 {
  padding-bottom: 30px;
}

.bottompadding-floating {
  padding-bottom: 93px;
}
@media screen and (max-width: 575px) {
  .bottompadding-floating {
    padding-bottom: 107px;
  }
}

.bottompadding-floating-extended {
  padding-bottom: 125px;
}
@media screen and (max-width: 575px) {
  .bottompadding-floating-extended {
    padding-bottom: 159px;
  }
}

/*------------------------------------*\
    # BORDERS
\*------------------------------------*/
.border-white {
  border: 3px solid #fff;
}

.border-gray-lighter {
  border: 3px solid #fafafa;
}

.border-gray-light {
  border: 3px solid #eee;
}

.border-gray {
  border: 3px solid #666;
}

.border-gray-dark {
  border: 3px solid #555;
}

.border-gray-darker {
  border: 3px solid #292929;
}

.border-gray-darkest {
  border: 3px solid #111;
}

.border-green {
  border: 3px solid #85d02f;
}

.border-thin {
  border-width: 1px;
}

.border-thick {
  border-width: 5px;
}

/*------------------------------------*\
    # TEXT
\*------------------------------------*/
.project-card-bg .title, .team-card-bg .title, .darkbg {
  color: #fafafa !important;
}

.bm-item span {
  /* Ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*------------------------------------*\
    # CURSOR TYPES
\*------------------------------------*/
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/* to be paired with react-dropdown */
/* https://github.com/trendmicro-frontend/react-dropdown */
/*------------------------------------*\
    # IFRAMES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/**
 * Expanded sizing for beyond 1280px screen widths
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/* ---------- FONT-FACE DECLARATIONS ---------- */
@font-face {
  font-family: "Arial Rounded";
  src: url("../../fonts/core/arial_rounded_bold.woff2") format("woff2"), url("../../fonts/core/arial_rounded_bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
/* ---------- RESETS ---------- */
html, body {
  padding: 0;
  margin: 0;
  /* Allows content to fill the viewport and go beyond the bottom */
  height: 100%;
}

html.ios-body, body.ios-body {
  /* Allows you to scroll below the viewport; default value is visible */
  overflow-y: scroll;
  /* To smooth any scrolling behavior */
  -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4, h5, h6, p, form, button {
  margin: 0;
  padding: 0;
}

ul.reset-list, li.reset-list-item {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

img {
  border: 0;
}

input, textarea, select, option {
  outline: none;
  border: none;
}

.box-sizing-border-box {
  box-sizing: border-box;
}

.box-sizing-border-box-all, .box-sizing-border-box-all * {
  box-sizing: border-box;
}

/* ---------- LAYOUT ---------- */
/* Required for hack to render page-specific background */
#root {
  height: 100%;
}

[data-reactroot] {
  height: 100%;
}

.page-wrapper {
  height: 100%;
}

.page-container {
  height: 100%;
  display: flex;
  margin-bottom: 0;
}
.page-container .sidebar-wrapper {
  flex-grow: 1;
}
.page-container .page {
  flex-grow: 3;
}
.page-container .sidebar-wrapper, .page-container .page {
  flex-basis: 0;
}

/* Extra padding to accommodate fixed navbar */
.page {
  height: 100%;
}

/*
  .component-container's height and padding changes depending on whether topbar is mounted.
*/
.component-container {
  /*
    Set height which will be overriden by min-height anyway.
    This height is necessary so that child components can
    use height: 100%;
  */
  height: 1px;
  min-height: 100%;
}

.topbar-wrap ~ .page-container .component-container {
  padding-top: 60px;
  min-height: calc(100% - 60px);
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}
.container .innerblock {
  width: 100%;
  max-width: 64em;
  margin: 0 auto;
  box-sizing: border-box;
}

.relative {
  position: relative !important;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.fullwidth {
  width: 100%;
}

.zerobottom {
  bottom: 0 !important;
}

table.fixedlayout {
  table-layout: fixed;
  margin-bottom: 0px;
}

.main-background {
  background: #fff;
}

.darkbg {
  background-color: #111;
}

.highlightbg {
  background-color: #bc1954;
  color: #fff;
}
.highlightbg:hover {
  background-color: #a5164a;
}

.topbar {
  height: 60px;
}

.topbarbg {
  background: #111;
  color: #fafafa;
}

.sidebarbg {
  background: #f6f6f6;
  color: #292929;
}
.sidebarbg .smalltext {
  color: #666;
}

.display-block {
  display: block !important;
}

.display-inline-block {
  display: inline-block !important;
}

@media screen and (max-width: 35.49em) {
  .hide-below-sm {
    display: none !important;
  }
}
@media screen and (max-width: 39.99em) {
  .hide-below-md-640 {
    display: none !important;
  }
}
@media screen and (max-width: 47.99em) {
  .hide-below-md {
    display: none !important;
  }
}
@media screen and (max-width: 63.99em) {
  .hide-below-lg {
    display: none !important;
  }
}
@media screen and (max-width: 79.99em) {
  .hide-below-xl {
    display: none !important;
  }
}
@media screen and (min-width: 35.5em) {
  .hide-from-sm {
    display: none !important;
  }
}
@media screen and (min-width: 40em) {
  .hide-from-md-640 {
    display: none !important;
  }
}
@media screen and (min-width: 48em) {
  .hide-from-md {
    display: none !important;
  }
}
@media screen and (min-width: 64em) {
  .hide-from-lg {
    display: none !important;
  }
}
@media screen and (min-width: 80em) {
  .hide-from-xl {
    display: none !important;
  }
}
.hidden {
  display: none !important;
}

/* ---------- ALIGNMENT ---------- */
.inline {
  display: inline;
}

.table {
  display: table;
}

.tablecell {
  display: table-cell;
}

/* Flex settings */
.flex {
  display: flex;
}

@media screen and (max-width: 35.49em) {
  .flex-below-sm {
    display: flex;
  }
}
@media screen and (max-width: 47.99em) {
  .flex-below-md {
    display: flex;
  }
}
@media screen and (max-width: 63.99em) {
  .flex-below-lg {
    display: flex;
  }
}
@media screen and (max-width: 79.99em) {
  .flex-below-xl {
    display: flex;
  }
}
@media screen and (min-width: 35.5em) {
  .flex-from-sm {
    display: flex;
  }
}
@media screen and (min-width: 48em) {
  .flex-from-md {
    display: flex;
  }
}
@media screen and (min-width: 64em) {
  .flex-from-lg {
    display: flex;
  }
}
@media screen and (min-width: 80em) {
  .flex-from-xl {
    display: flex;
  }
}
.flex-shrink-none {
  flex-shrink: 0;
}

.flex-grow-none {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-wrap-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-flexend {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-flexstart {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

@media screen and (max-width: 35.5em) {
  .justify-content-sm-center {
    justify-content: center;
  }
}

.verticalcenter, .center { /* usage: parent must have position:relative */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.verticalcenter.textright, .textright.center {
  right: 0;
}

.center { /* usage: parent must have position:relative */ }

.imgcenter {
  display: block;
  margin: auto;
}

.textcenter, .center {
  text-align: center;
}

.textright {
  text-align: right;
}

.floatright {
  float: right;
}

.floatleft {
  float: left;
}

.alignmiddle {
  vertical-align: middle;
}

/* ---------- MARGIN & PADDING ---------- */
@media screen and (min-width: 64em) {
  .on-lg-horizontalpadding {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.nopadding {
  padding: 0px;
}

@media screen and (max-width: 48em) {
  .below-md-nopadding {
    padding: 0px;
  }
}

.fullheight {
  height: 100%;
}

.inheritheight {
  height: inherit;
}

.translucent {
  opacity: 0.5;
}

/* ---------- BORDERS ---------- */
/* ---------- IMAGES ---------- */
.square-image-wrapper {
  display: block;
  position: relative;
}

.square-image {
  display: block;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  width: 100%;
}

.square-image img {
  position: absolute;
  object-fit: cover;
  height: auto;
  min-height: 100%;
  left: 0;
  top: 0;
  border: 0;
  width: 100%;
}

.lockoverlay { /* to be placed above (adjacent) .square-image html elements */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  background-image: url("../../../images/core/placeholders/item_locked_icon_large@2x.png");
  background-size: cover;
}

.imgresponsive {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.iconxs {
  width: 37px;
  height: 37px;
}

.iconxs-flex-basis {
  flex-basis: 37px;
}

.iconsmall {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: black;
  margin: 0 1.5px;
}

.iconmedium {
  width: 80px;
  height: 80px;
}

.iconlarge {
  width: 120px;
  height: 120px;
}

.circle {
  border-radius: 50%;
}

/* ---------- DECORATION ---------- */
.boxshadow {
  box-shadow: 1px 1px 3px #999;
}

.highlightborder {
  border-color: #bc1954;
}

/* ---------- POSITION ---------- */
/* Sticky positioning once element scrolls off the screen, offsets top bar only */
.position-sticky-offset-topbar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 60px;
  z-index: 19;
}

/* ---------- INPUTS ---------- */
textarea {
  resize: none;
  height: 100px;
}

/*
  Force disabled fields to use readonly styling
*/
.pure-form select.pseudo-readonly[disabled] {
  background-color: #eee;
  color: #777;
  border: 2px solid #ccc;
  opacity: 1;
}

/*
  Disabled fields do not allow onClick handlers to be attached,
  so for most inputs readOnly suffices. However, for fields like <select>,
  where there is no readily available readOnly flag, I rely on
  a hack where I disable the field, as well as add an overlay (div) on
  top of the field to trigger any onClick handlers.
*/
.disable-check-input-container {
  position: relative;
  z-index: 1;
}
.disable-check-input-container .input-disabled-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

/* ---------- INPUTS: UPLOADERS ---------- */
.image-input-label {
  color: #666;
  border: 3px dashed #999999;
}
.image-input-label .fas {
  color: #CC0000;
}
.image-input-label:hover {
  color: gray;
  border: 3px dashed #b3b3b3;
}
.image-input-label:hover .fas {
  color: red;
}
.image-input-label.disabled .fas {
  color: #666;
}
.image-input-label.disabled:hover .fas {
  color: gray;
}

/* Old image uploader */
/* ---------- NON-TEXT ANCHORS ---------- */
a.link {
  color: inherit;
}

a.link:hover {
  text-decoration: none;
}

/* ---------- CURSOR TYPES ---------- */
/* ---------- SLIDER ---------- */
/*
  <div> adjacent selectors applicable to react-slick v0.27.11,
  can remove once there's a breaking update that removes the additional divs
*/
.slick-slider:focus, .slick-list .slick-track:focus, .slick-slide:focus, .slick-active:focus, .slick-current:focus, .slick-slide > div:focus, .slick-slide > div > div:focus {
  outline: none;
}

/* ---------- PROGRESS BAR ---------- */
.progressbarbg {
  background-color: #fff;
}
.progressbarbg .progress-block {
  background-color: #85d02f;
}

/* ---------- FLOATING MENU BUTTON ---------- */
/* Background of child and main buttons */
.mfb-component__button--main, .mfb-component__button--child {
  background-color: #CC0000;
}

/* Bars and lines of cross and bars icon of main button */
.floating-menu-icon-color span {
  background: #fff;
}

/* Font Awesome icons of child buttons */
a.link.mfb-component__button--child {
  color: #fff;
}

/* ---------- TOPBAR MENU ---------- */
/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #fff;
}

.bm-cross-button button:focus {
  outline: 0;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 0;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #000;
  padding: 2.5em 0 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #fff;
  padding: 0.8em 0;
}
.bm-item-list:visited, .bm-item-list:focus {
  color: #fff;
}

/* Individual item */
.bm-item {
  padding: 0.8em 2.3em;
}
.bm-item.link {
  color: #fff;
}
.bm-item:hover {
  background: #CC0000;
  color: #fff;
}
.bm-item i {
  margin-right: 10px;
}
.bm-item i, .bm-item span {
  display: block;
}
.bm-item.logout-link {
  display: flex;
  align-items: center;
}
.bm-item.menu-divider {
  padding: 0;
  margin: 0.8em 1.5em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.bm-item.menu-divider:hover {
  background: transparent;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* ---------- PROFILE DRAWER ---------- */
.drawer-profile-wrapper-bg {
  background: rgba(0, 0, 0, 0.85);
}

.drawer-profile-img-border {
  border: 2px solid #bbb;
}

/* ---------- LOGIN & REGISTER PAGES ---------- */
.login-register-container .button.cta, .login-register-container .button.cta a {
  background: #CC0000;
}

/* ---------- PROJECTS LIST PAGE ---------- */
.project-card-container .search-bar .button {
  position: static;
}

/* ---------- PROJECTS LIST PAGE: PROJECT CARDS ---------- */
.project-card-bg, .team-card-bg {
  background: black;
}
.project-card-bg p, .team-card-bg p {
  color: #bababa;
  font-size: 0.875em;
}
.project-card-bg .title, .team-card-bg .title {
  font-weight: bold;
}
.project-card-bg .square-image, .team-card-bg .square-image {
  border: 1px solid #fff;
}

/* ---------- PROJECT PAGE: TOPIC CARDS ---------- */
.card-action {
  background-color: #CC0000;
}

.card-locked {
  background-color: #bbb;
}

/* ---------- PROJECT PAGE: CATEGORY SECTION ---------- */
.category-img {
  border: 3px solid #ddd;
}

/* ---------- MOBILE PROJECT PAGE: TOPBAR ---------- */
.mobile-project-topbar-wrap .topbarbg {
  background: #111;
  color: #fafafa;
}

/* ---------- MOBILE PROJECT PAGE: SIDEBAR ---------- */
/*
  General text color in .mobile-project-sidebar,
  for: Name section and points section
*/
.mobile-project-sidebar {
  color: #fff;
}

a.mobile-project-profile-button, button.mobile-project-profile-button {
  background: #000;
  color: #fff;
  /*
    For regular buttons with no image inserted:
    Padding-top to use calc(100% - [borderSize] * 2)
  */
  padding-top: calc(100% - 2px * 2);
}

.mobile-project-profile-button-border {
  border: 2px solid #fff;
}
.mobile-project-profile-button-border.square-image {
  /*
    For buttons with image inserted - code is repeated here
    because .mobile-project-profile-button-border may not be
    attached to gether with .mobile-project-profile-button:
    Padding-bottom to use calc(100% - [borderSize] * 2)
  */
  padding-bottom: calc(100% - 2px * 2);
}

.sidebar-links a {
  background: #fafafa;
  color: #000;
  /*
    margin-left depends on the value of the border width, if
    sidebar link buttons require borders for custom projects:
    with a border-width of 3px, margin-left should be -3px
  */
  margin-left: 0;
}

/* ---------- MOBILE PROJECT PAGE: PROFILE SECTION ---------- */
.mobile-project-sidebar .mobile-project-profile {
  background: rgba(0, 0, 0, 0);
  padding: 10px 10px 0px 15px;
}

/* ---------- MOBILE PROJECT PAGE: TOPIC CARD SLIDER ---------- */
.swipe-to-nav {
  border-radius: 16px;
  padding: 5px 0 5px 5px;
  color: white;
  height: 42px;
  box-sizing: content-box;
  position: absolute;
  top: 86px;
  right: 0;
  text-align: right;
  font-size: 0.875em;
  display: flex;
  align-items: center;
}

.swipe-to-nav-text {
  max-width: 60px;
}

.project .mobile-project {
  background: url("../../../images/core/project/gamelist_bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.slick-slider.topic-card-slider {
  height: 100%;
}
.slick-slider.topic-card-slider button.slick-arrow { /* prevent messing up the page width */
  display: none !important;
}
.slick-slider.topic-card-slider .slick-dots {
  background-color: transparent;
  position: static;
  padding-bottom: 10px;
}
.slick-slider.topic-card-slider .slick-dots li {
  margin: 0 !important;
}
.slick-slider.topic-card-slider .slick-dots li.slick-active button::before {
  color: white;
  opacity: 1;
}
.slick-slider.topic-card-slider .slick-dots li button::before {
  color: white;
  opacity: 0.25;
}

/* ---------- MOBILE PROJECT PAGE: PROJECT TITLE CARD ---------- */
.project-title-card-bg, .featured-project-card-bg {
  background: rgba(17, 17, 17, 0.8);
  color: #fafafa;
  border: 1px solid #fff;
}
@media screen and (min-width: 48em) {
  .project-title-card-bg, .featured-project-card-bg {
    background: #111;
    color: #ededed;
    border: 0;
  }
}

/* ---------- TEAMS PAGE: TEAM CARD ---------- */
.team-card-bg .title {
  font-weight: normal;
}

/* ---------- CHALLENGE PAGE: GRID QUIZ VIEW ---------- */
/* Grid View for Options */
@media screen and (max-width: 47.99em) {
  .grid-quiz-board-container.scrollable-map-activated .grid-quiz-board-scrollable-instruction {
    display: block;
    text-align: center;
    color: #666;
    margin-bottom: 20px;
    font-size: 0.875em;
  }
  .grid-quiz-board-container.scrollable-map-activated .grid-quiz-board-scrollable-map {
    position: relative;
    width: 100%;
    max-height: 100vw;
    overflow-x: scroll;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .grid-quiz-board-container.scrollable-map-activated .grid-quiz-board-wrap {
    width: 100vw;
  }
}

/* Grid View Scrollable Map
   (Only for gridWidth > 2 in mobile views) */
.grid-quiz-board-scrollable-instruction {
  display: none;
}

.grid-quiz-board-scrollable-map {
  border: 2px solid #666;
}

/* Grid View Wrapper */
.grid-quiz-board-wrap {
  display: flex;
}

.grid-quiz-board {
  table-layout: fixed;
  width: 100%;
  margin: 0;
  border: none;
  border-collapse: collapse;
  border-style: hidden;
}
.grid-quiz-board td {
  border: 1px solid #eee;
}
.grid-quiz-board td, .grid-quiz-board tr {
  padding: 0;
  margin: 0;
}
.grid-quiz-board .button {
  margin: 0;
}

/* display user's rank status image */
.photo-icon-wrap {
  position: relative;
  width: 100%;
}
.photo-icon-wrap .rank-status {
  width: 30px;
  height: 30px;
  background-size: contain;
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 2;
}
.photo-icon-wrap .xs-rank-status {
  width: 20px !important;
  height: 20px !important;
}
.photo-icon-wrap .s-rank-status {
  width: 25px !important;
  height: 25px !important;
}
.photo-icon-wrap .l-rank-status {
  width: 60px !important;
  height: 60px !important;
}
