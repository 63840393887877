/**
 * 1. Define Open Sans
 * 2. Set default font family to Open Sans.
 */

@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/custom/OpenSans/OpenSans-Light.woff2") format("woff2"), url("../../fonts/custom/OpenSans/OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/custom/OpenSans/OpenSans-LightItalic.woff2") format("woff2"), url("../../fonts/custom/OpenSans/OpenSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/custom/OpenSans/OpenSans-Regular.woff2") format("woff2"), url("../../fonts/custom/OpenSans/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/custom/OpenSans/OpenSans-Italic.woff2") format("woff2"), url("../../fonts/custom/OpenSans/OpenSans-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/custom/OpenSans/OpenSans-Semibold.woff2") format("woff2"), url("../../fonts/custom/OpenSans/OpenSans-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/custom/OpenSans/OpenSans-SemiboldItalic.woff2") format("woff2"), url("../../fonts/custom/OpenSans/OpenSans-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/custom/OpenSans/OpenSans-Bold.woff2") format("woff2"), url("../../fonts/custom/OpenSans/OpenSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/custom/OpenSans/OpenSans-BoldItalic.woff2") format("woff2"), url("../../fonts/custom/OpenSans/OpenSans-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/custom/OpenSans/OpenSans-ExtraBold.woff2") format("woff2"), url("../../fonts/custom/OpenSans/OpenSans-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url("../../fonts/custom/OpenSans/OpenSans-ExtraBoldItalic.woff2") format("woff2"), url("../../fonts/custom/OpenSans/OpenSans-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}


html {
  font-family: 'Open Sans', sans-serif; /* 1 */
}

/**
 * Removed 'Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet'. Set line-height to inherit for inputs
 */

input {
  line-height: inherit;
}
