.challenge-card {
  display: block;
  position: relative;
  margin: 20px auto;
  padding-top: 100%;
  background: #999;
  border-radius: 10px;
  background-image: linear-gradient(rgba(20, 20, 20, 0.3), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(20, 20, 20, 0.3));
  background-size: cover;
  background-position: center center;
  box-shadow: 1px 1px 3px #999;
}
.challenge-card .challenge-card-link-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2; /* more than .challenge-card (default = 1) */
}
.challenge-card .challengecard-bottom {
  height: 0;
}
.challenge-card .locked-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.challenge-card .icons-wrap {
  position: absolute;
  top: -20px;
  left: 0px;
  right: 0px;
  text-align: center;
  z-index: 4; /* more than .challenge-card-link-overlay and .challengecard-description-wrap */
  display: block;
}
.challenge-card .icons-wrap .icons {
  display: inline-block;
}
.challenge-card .challengecard-description-wrap {
  position: absolute;
  top: 0;
  z-index: 3; /* more than .challenge-card-link-overlay */
  display: block;
  width: 100%;
}
.challenge-card .challengecard-description-wrap .challengecard-description {
  background: rgba(0, 0, 0, 0.25);
  padding: 30px 20px 20px;
  font-size: 1em;
  color: #fff;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
}
.challenge-card .challengecard-description-wrap, .challenge-card .challengecard-description-wrap * {
  box-sizing: border-box;
}
.challenge-card .stats-wrap {
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  padding-bottom: 30px;
  padding-left: 20px;
  text-shadow: 0 0 6px #666;
  font-size: 0.875em;
}
.challenge-card .stats-wrap .stats-text {
  background: rgba(0, 0, 0, 0.25);
  text-shadow: 0.5px 0.5px 3px #333;
  padding: 2.5px 4px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.challenge-card .button-wrap {
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding-bottom: 20px;
  padding-right: 20px;
  text-align: right;
}
.challenge-card .button-wrap button:not(.button-challenge-card) {
  z-index: 3; /* more than .challenge-card-link-overlay */
}
